import IntlImpl from './IntlImpl'

const Intl = {
  simpleTranslate(path, defaultMessage = '') {
    return IntlImpl.get().simpleTranslate(path, defaultMessage)
  },
  translate(
    path,
    literalValues,
    translateValues,
    defaultMessage,
    allowNull = false
  ) {
    return IntlImpl.get().translate(path, literalValues, translateValues, defaultMessage, allowNull)
  },
  addQualifier(qualifier) {
    IntlImpl.get().addQualifier(qualifier)
  },
  clearQualifiers() {
    IntlImpl.get().clearQualifiers()
  },
  setLocale(locale) {
    IntlImpl.get().setLocale(locale)
  }
}

export default Intl
