import Div from '../Div'
import {isEmpty} from '../../../../utils/GeneralUtils'
import UI from '../UI'

export const TYPE_SM = 'sm'
export const TYPE_MD = 'md'
export const TYPE_LG = 'lg'

function Section({content = [], type = TYPE_MD}) {
  function getUI() {
    const containerUI = Div({classNames: `a1mv__section_${type}`})

    if (!isEmpty(content)) {
      content.forEach((contentUI) => {
        UI.add(containerUI, contentUI)
      })
    }

    return containerUI
  }

  return getUI()
}

export default Section
