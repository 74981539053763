/* eslint-disable no-plusplus */
import {ACTION_TYPE, getActionObj} from '../../../../utils/ActionsUtils'
/* eslint-disable max-len */
import {
  getValue,
  hackFixJSON,
  isEmpty
} from '../../../../utils/GeneralUtils'

import Anchor from '../../base/Anchor'
import Div from '../../base/Div'
import Image from '../../base/Image'
import Intl from '../../../../intl/Intl'
import PopupActionButton from '../PopupActionButton'
import PopupMainContainer from '../PopupMainContainer'
import UI from '../../base/UI'

function PopupRASite(feature, callback, mainStyle = '', isHighlight = false, isHover = false) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  hackFixJSON(feature.properties)
  const {uiData} = feature.properties

  function getHoverUI() {
    let hoverUI = null
    const head = getValue(() => uiData.head, null)
    if (feature.properties.baseMarkerIconType === 'amenity') {
      let title = getValue(() => head.title, null)
      if (isEmpty(title)) {
        title = Intl.simpleTranslate(`markerIconType_${feature.properties.markerIconType}`, '')
      }
      if (!isEmpty(title)) {
        hoverUI = Div({content: title, classNames: 'amenity'})
      }
    } else {
      hoverUI = Div({content: `${head.siteName}, ${head.siteType}`, classNames: 'siteDetails'})
    }
    return hoverUI
  }

  function getHeadUI() {
    const containerUI = Div({classNames: 'head'})
    const head = getValue(() => uiData.head, null)

    if (!isEmpty(head)) {
      const accessible = head.accessible === 'true'
      const siteNameContainerUI = Div({classNames: 'site_name_container'})
      if (accessible) {
        const accessibleUI = Image({classNames: 'site_accessible', src: '/gis-maps/assets/icons/dots/handicapAccess.png', alt: 'Accessible', title: 'Accessible'})
        UI.add(siteNameContainerUI, accessibleUI)
      }
      const siteNameUI = Div({classNames: 'site_name', content: head.siteName})

      UI.add(siteNameContainerUI, siteNameUI)
      UI.add(containerUI, siteNameContainerUI)

      const attrCol = Div({classNames: 'head-subtitle'})
      const siteLoopUI = Div({content: `<span>Loop:</span> <span class="bold">${head.loop}</span>, <span>Site Type:</span> <span class="bold">${head.siteType}</span>`})
      UI.add(attrCol, siteLoopUI)
      UI.add(containerUI, attrCol)
    }
    return containerUI
  }

  function getImageUI() {
    const image = getValue(() => uiData.image, null)

    if (!isEmpty(image)) {
      const containerUI = Div({classNames: 'image f-left'})
      const imageUI = Image({classNames: 'site_image', src: image.imageURL, alt: image.alt, title: image.title, defaultImg: image.defaultImg})
      UI.add(containerUI, imageUI)
      return containerUI
    }

    return null
  }

  function getCTAUI() {
    const containerUI = Div({classNames: 'imgCTA f-left clearfix'})

    const imageUI = getImageUI()
    if (!isEmpty(imageUI)) {
      UI.add(containerUI, imageUI)
    }

    const ctaWrapperUI = Div({classNames: 'ctaWrapper f-left'})
    if (!isEmpty(uiData.cta)) {
      for (let i = 0; i < uiData.cta.length; i++) {
        const action = uiData.cta[i]

        if (action.isButton) {
          const button = PopupActionButton({
            id: action.id,
            classNames: action.style,
            label: action.label,
            disabled: action.disabled,
            onClick: () => {
              callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, action.id))
            }
          })
          UI.add(ctaWrapperUI, button)
        } else if (action.isLink) {
          const ctaLink = Anchor({
            id: action.id,
            classNames: action.style,
            content: action.label,
            url: null,
            onClick: (event) => {
              callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, action.id))
            }
          })
          const linkUI = Div({classNames: 'site_link'})
          UI.add(linkUI, ctaLink)
          UI.add(ctaWrapperUI, linkUI)
        } else {
          const linkUI = Div({classNames: 'site_text', content: action.label})
          UI.add(ctaWrapperUI, linkUI)
        }
      }
    }

    if (!isEmpty(feature.geometry) && !isEmpty(feature.geometry.coordinates) && feature.geometry.coordinates.length === 2) {
      const lat = typeof feature.geometry.coordinates[1] === 'number' ? feature.geometry.coordinates[1] : parseFloat(feature.geometry.coordinates[1])
      const long = typeof feature.geometry.coordinates[0] === 'number' ? feature.geometry.coordinates[0] : parseFloat(feature.geometry.coordinates[0])
      const coordsUI = Div({classNames: 'coordinates', content: `${lat.toFixed(5)}, ${long.toFixed(5)}`})
      UI.add(ctaWrapperUI, coordsUI)
    }

    UI.add(containerUI, ctaWrapperUI)

    return containerUI
  }

  function getSectionsUI() {
    const containerUI = Div({classNames: 'section'})
    const sections = getValue(() => uiData.sections, null)

    if (!isEmpty(sections)) {
      sections.forEach((section) => {
        const attributes = getValue(() => section.attributes, null)
        if (!isEmpty(attributes)) {
          const attrCol = Div({classNames: 'attributes'})

          attributes.forEach((attribute) => {
            const attributeUI = Div({content: `<span>${attribute.title}</span>: <span class="bold">${attribute.value}</span>`})
            UI.add(attrCol, attributeUI)
          })
          UI.add(containerUI, attrCol)
        }
      })
    }

    if (!isEmpty(uiData.nssSummary)) {
      const nssDiv = Div({classNames: 'nssSummary'})
      const nssSummaryUI = Div({content: `<span class="note"><sup>+</sup></span>${uiData.nssSummary}`})
      UI.add(nssDiv, nssSummaryUI)
      UI.add(containerUI, nssDiv)
    }

    return containerUI
  }

  function getAttributeUI() {
    const mainContainer = Div({classNames: 'raSite-oneCol'})

    const sectionsUI = getSectionsUI()
    if (!isEmpty(sectionsUI)) {
      UI.add(mainContainer, sectionsUI)
    }

    return mainContainer
  }

  function getMainUI() {
    if (isEmpty(uiData)) {
      return null
    }
    const mainContainer = PopupMainContainer({mainStyle})

    let content = null
    if (isHover || feature.properties.baseMarkerIconType === 'amenity' || feature.properties.isSiteDetails) {
      content = getHoverUI()
      UI.add(mainContainer, content)
      return mainContainer
    }

    if (isHighlight) {
      const headerUI = Div({classNames: 'header-row'})
      UI.add(headerUI, getHeadUI())
      UI.add(mainContainer, headerUI)

      UI.add(mainContainer, getCTAUI())
      UI.add(mainContainer, getAttributeUI())
    } else {
      const headerUI = Div({classNames: 'header-row'})
      UI.add(headerUI, getHeadUI())
      UI.add(mainContainer, headerUI)

      UI.add(mainContainer, getCTAUI())
      UI.add(mainContainer, getAttributeUI())
    }
    
    if(content){
      UI.add(mainContainer, content)
    }

    return mainContainer
  }

  return getMainUI()
}

export default PopupRASite
