/* eslint-disable max-len */
import {
  isEmpty,
  hackFixJSON,
  getValue,
  log
} from '../../../../utils/GeneralUtils'
import {ACTION_TYPE, getActionObj} from '../../../../utils/ActionsUtils'
import Div from '../../base/Div'
import UI from '../../base/UI'
import PopupMainContainer from '../PopupMainContainer'
import PopupActionButton from '../PopupActionButton'
import PopupSections from './PopupSections'
import Intl from '../../../../intl/Intl'

// eslint-disable-next-line no-unused-vars
function PopupFMSiteTest({
  feature, callback, mainStyle = '', isHighlight = false, isHover = false
}) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  hackFixJSON(feature.properties)
  const {uiData} = feature.properties

  function getHead() {
    const containerUI = Div({id: 'head', classNames: 'head'})
    let title = getValue(() => uiData.head.title, null)
    if (isEmpty(title)) {
      title = Intl.simpleTranslate(`markerIconType_${uiData.summary.titleTextFallbackIntlToken}`, '')
    }
    if (!isEmpty(title)) {
      const titleUI = Div({classNames: 'gismaps-popup-title', content: uiData.head.title})
      UI.add(containerUI, titleUI)
    }

    return containerUI
  }

  function getActionButtons() {
    const containerUI = Div({classNames: 'action-buttons'})

    if (!isEmpty(uiData.mainActions)) {
      const actionItems = uiData.mainActions
      actionItems.forEach((action) => {
        const button = PopupActionButton({
          id: action.id,
          classNames: action.style,
          label: action.label,
          disabled: action.disabled,
          onClick: (event) => {
            callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, event.target.id))
          }
        })
        UI.add(containerUI, button)
      })
    }

    return containerUI
  }

  function getMainUI() {
    const mainContainer = PopupMainContainer({mainStyle})
    UI.add(mainContainer, getHead())

    if (!isHover) {
      UI.add(mainContainer, PopupSections({uiData}))
      UI.add(mainContainer, getActionButtons())
    }

    return mainContainer
  }

  return getMainUI()
}

export default PopupFMSiteTest
