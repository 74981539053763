import Anchor from '../Anchor'
import Div from '../Div'
import UI from '../UI'
import {isEmpty} from '../../../../utils/GeneralUtils'

function ExpandableText({
  id = '', textClassNames = '', anchorClassnames = '', text = null, revealLabel = ' ...', hideLabel = ' Show Less', maxChars = 100, expanded = false
}) {
  let uiContainer = null
  let textUIOuterContainer = null
  let currExpanded = null

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return `${str.slice(0, num)}`
  }

  function getDescriptionUI() {
    let str = text
    if (!currExpanded) {
      str = truncateString(text, maxChars)
    }
    if (isEmpty(text)) {
      return null
    }
    textUIOuterContainer = Div({
      classNames: 'a1mv__text-truncate'
    })
    const textUI = Div({
      asSpan: true, classNames: textClassNames, content: str
    })

    const textActionUI = Anchor({
      content: currExpanded ? hideLabel : revealLabel,
      classNames: isEmpty(anchorClassnames) ? 'a1mv__anchor' : anchorClassnames,
      // eslint-disable-next-line no-use-before-define
      onClick
    })

    UI.add(textUIOuterContainer, textUI)
    if (text.length > maxChars) {
      UI.add(textUIOuterContainer, textActionUI)
    }

    return textUIOuterContainer
  }

  function onClick() {
    currExpanded = !currExpanded
    UI.remove(uiContainer, textUIOuterContainer)
    UI.add(uiContainer, getDescriptionUI())
  }

  function getUI() {
    currExpanded = expanded
    uiContainer = Div({
      id
    })

    const desctiptionUI = getDescriptionUI()
    if (!isEmpty(desctiptionUI)) {
      UI.add(uiContainer, desctiptionUI)
    }
    return uiContainer
  }

  return getUI()
}

export default ExpandableText
