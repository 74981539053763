import Div from '../../base/Div'
import Image from '../../base/Image'
import Overlay, {POSITION_BOTTOM_RIGHT} from '../../base/overlay/Overlay'
import UI from '../../base/UI'
import {isEmpty, log} from '../../../../utils/GeneralUtils'
import Intl from '../../../../intl/Intl'
import Category from './Category'
import {getMapActionObj, MAP_ACTION_TYPE, MAP_ACTION_ID} from '../../../../utils/MapActionsUtils'

function CategoriesUI({categoryData = null, mapActionCallback = null, onClose}) {
  let uiContainer = null
  let contentContainer = null

  function getHeaderUI() {
    const headerUI = Div({})
    const icon = Image({classNames: 'a1mv__categories_title-icon', src: '/gis-maps/assets/icons/categories/cat_icon_category.png'})
    const content = Div({content: 'Categories'})

    UI.add(headerUI, icon)
    UI.add(headerUI, content)

    return headerUI
  }

  function onCategorySelectionChange(featureType, category) {
    log('onCategorySelectionChange', category)
    if (!isEmpty(featureType)) {
      categoryData.categories.forEach((currCategory) => {
        if (currCategory.categoryId !== category.categoryId) {
          currCategory.featureTypes.forEach((currFeatureType) => {
            if (currFeatureType.type === featureType.type) {
              // eslint-disable-next-line no-param-reassign
              currFeatureType.active = featureType.active
            }
          })
        }
      })

      UI.remove(uiContainer, contentContainer)
      // eslint-disable-next-line no-use-before-define
      UI.add(uiContainer, getContentUI())
    }
    mapActionCallback(getMapActionObj(
      MAP_ACTION_TYPE.DATA_CHANGE,
      null,
      MAP_ACTION_ID.CATEGORY_DATA
    ))
  }

  function getContentUI() {
    log('CategoriesUI', categoryData)
    contentContainer = Div({classNames: 'a1mv__pane-container'})

    if (!isEmpty(categoryData.cornerMarkerCat)) {
      const cornerMarkerCatUI = Category(
        {category: categoryData.cornerMarkerCat, onCategorySelectionChange}
      )
      const icon = cornerMarkerCatUI.querySelector('.a1mv__category_header-icon')
      icon.classList.add('a1mv__corner_marker_category_header-icon')
      icon.style.backgroundColor = categoryData.cornerMarkerCat.bgColor
      UI.add(contentContainer, cornerMarkerCatUI)
    }

    if (!isEmpty(categoryData.categories)) {
      categoryData.categories.forEach((category) => {
        const categoryContent = Category({category, onCategorySelectionChange})
        UI.add(contentContainer, categoryContent)
      })
    }

    return contentContainer
  }

  function createCategoriesUI() {
    uiContainer = Div({})
    UI.add(uiContainer, getContentUI())

    const overlay = Overlay({
      id: 'categories-overlay', position: POSITION_BOTTOM_RIGHT, header: getHeaderUI(), content: uiContainer, onClose
    })

    const mainContainer = Div({})
    UI.add(overlay, mainContainer)

    return overlay
  }

  if (isEmpty(categoryData)) {
    return null
  }

  return createCategoriesUI()
}

export default CategoriesUI
