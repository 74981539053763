import Checkbox from './Checkbox'
import Div from './Div'
import Label from './Label'

function Switch({
  id = '', disabled = false, checked = null, onChange = null
}) {
  function getUI() {
    const cont = Label({classNames: 'a1mv__switch'})
    const cb = Checkbox({
      id, onChange, disabled, checked
    })
    const innerSpan = Div({asSpan: true, classNames: 'a1mv__slider a1mv__round'})

    cont.appendChild(cb)
    cont.appendChild(innerSpan)

    return cont
  }

  return getUI()
}

export default Switch
