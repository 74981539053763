import {ACTION_TYPE, getActionObj} from '../../../utils/ActionsUtils'
import {
  getAnchor,
  getDiv,
  getImage,
  getItalicUI,
  getMainContainer
} from '../../../uiBuilders/UIUtil'
/* eslint-disable max-len */
import {
  hackFixJSON,
  isEmpty
} from '../../../utils/GeneralUtils'

function PopupRAFacility(feature, callback, mainStyle = '', isHighlight = false) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  hackFixJSON(feature.properties)
  const {uiData} = feature.properties

  function getEmptyDiv() {
    return getDiv('')
  }

  function getOuterContainer() {
    return getDiv('', 'facility-summary-card facility-summary-card--active')
  }

  function getInnerContainer() {
    return getDiv('', 'facility-summary-card__container1')
  }

  function getImageSection() {
    const imageContainer = getDiv(uiData.image.id, 'facility-summary-card__image-wrapper')
    imageContainer.addEventListener('click', () => {
      callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, uiData.image.id))
    })

    let imageStyle = ''
    if (!isEmpty(uiData.image.url)) {
      imageStyle = `background-image: url('${uiData.image.url}');`
    }

    // eslint-disable-next-line prefer-template
    const image = getDiv('', 'facility-summary-card__image' + (uiData.title.nolink ? ' nolink' : ''), '', imageStyle)

    imageContainer.appendChild(image)

    return imageContainer
  }

  function getTitleUI() {
    const nameSpan = getDiv('', '', uiData.title.text, '', true)

    // affiliated states UI
    let affiliatedStates = ''
    if (!isEmpty(uiData.affiliatedStates)) {
      for (let index = 0; index < uiData.affiliatedStates.length; index += 1) {
        const prepender = ', '
        affiliatedStates = affiliatedStates + prepender + uiData.affiliatedStates[index]
      }
    }
    const affiliatedStatesSpan = getDiv('', '', affiliatedStates, '', true)

    // AA icon
    let aaIcon = null
    if (!isEmpty(uiData.aaIcon) && !isEmpty(uiData.aaIcon.url)) {
      aaIcon = getImage('', '', uiData.aaIcon.url, uiData.aaIcon.alt, uiData.aaIcon.title)
    }

    // eslint-disable-next-line prefer-template
    const titleSpan = getDiv('', 'facility-summary-card__title' + (uiData.title.nolink ? ' nolink' : ''), '', '', true)

    titleSpan.appendChild(nameSpan)
    titleSpan.appendChild(affiliatedStatesSpan)
    if (!isEmpty(aaIcon)) {
      titleSpan.innerHTML += '&nbsp;'
      titleSpan.appendChild(aaIcon)
    }

    if (!uiData.title.nolink) {
	    const nameLink = getAnchor(uiData.title.id, 'base-link')
	    nameLink.appendChild(titleSpan)
	    nameLink.addEventListener('click', () => {
	      callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, uiData.title.id))
	    })
	
	    return nameLink
	  }

    return titleSpan
  }

  function getRatingsUI() {
    if (isEmpty(uiData.ratings)) {
      return getEmptyDiv()
    }

    const ratingsDiv = getDiv('', 'rating', '', '', false)
    const ratingsSpan = getDiv('', 'rating', '', '', true)

    for (let i = 0; i < uiData.ratings.numberOfStars; i += 1) {
      ratingsSpan.appendChild(getItalicUI('fa fa-star', true))
    }
    for (let i = 0; i < (5 - uiData.ratings.numberOfStars); i += 1) {
      ratingsSpan.appendChild(getItalicUI('fa fa-star-o', true))
    }

    ratingsSpan.appendChild(getDiv('', '', uiData.ratings.ratingsLabel, '', true))

    ratingsDiv.appendChild(ratingsSpan)

    return ratingsDiv
  }

  function getAffiliationUI() {
    if (!isEmpty(uiData.affiliation) && !isEmpty(uiData.affiliation.agencies)) {
      const container = getEmptyDiv()
      // eslint-disable-next-line no-restricted-syntax
      for (const agency of uiData.affiliation.agencies) {
        container.appendChild(getDiv('', 'facility-summary-card__affiliation', agency.name))
      }
      return container
    }

    return getEmptyDiv()
  }

  function getClientLogoUI() {
    if (!isEmpty(uiData.affiliation) && !isEmpty(uiData.affiliation.clientLogoSource)) {
      const container = getDiv('', 'section-sub-inline-top')
      const clientLogo = getImage('', 'client-logo', uiData.affiliation.clientLogoSource)
      const spacer = getDiv('', 'section-sub-inline-top')

      container.appendChild(clientLogo)
      container.appendChild(spacer)

      return container
    }

    return getEmptyDiv()
  }

  function getClientLogoOrAffiliationUI() {
    if (!isEmpty(uiData.affiliation) && !isEmpty(uiData.affiliation.clientLogoSource)) {
      return getClientLogoUI()
    } if (!isEmpty(uiData.affiliation) && !isEmpty(uiData.affiliation.agencies)) {
      return getAffiliationUI()
    }

    return getEmptyDiv()
  }

  function getProximityUI() {
    if (!isEmpty(uiData.proximityMsg)) {
      const container = getDiv('', 'facility-summary-card__distance', uiData.proximityMsg)
      return container
    }

    return getEmptyDiv()
  }

  function getCTAUI() {
    if (!isEmpty(uiData.cta)) {
      const ctaLink = getAnchor(uiData.cta.id, 'base-link')
      const textSpan = getDiv('', 'action-link', uiData.cta.text, '', true)
      ctaLink.appendChild(textSpan)
      ctaLink.addEventListener('click', () => {
        callback(getActionObj(ACTION_TYPE.ACTION_ITEM_CLICK, feature.properties, uiData.cta.id))
      })
      return ctaLink
    }

    return getEmptyDiv()
  }

  function getAvailabilityUI() {
    if (!isEmpty(uiData.availability) && !isEmpty(uiData.availability.availabilityText)) {
      const ctaLink = getDiv('', `facility-availability-text ${uiData.availability.availabilityStatus}`, uiData.availability.availabilityText)
      return ctaLink
    }

    return getEmptyDiv()
  }

  function getDetailsSection() {
    const detailsContainer = getDiv('', 'facility-summary-card__detail')
    const row1 = getDiv('', 'row')
    const col1 = getDiv('', 'col')

    const titleUI = getTitleUI()
    col1.appendChild(titleUI)

    const ratingsUI = getRatingsUI()
    col1.appendChild(ratingsUI)

    const clientLogoOrAffiliationUI = getClientLogoOrAffiliationUI()
    col1.appendChild(clientLogoOrAffiliationUI)

    row1.appendChild(col1)
    detailsContainer.appendChild(row1)

    const row2 = getDiv('', 'row')
    const col2 = getDiv('', 'col')
    col2.appendChild(getProximityUI())
    row2.appendChild(col2)

    detailsContainer.appendChild(row2)

    const row3 = getDiv('', 'row')
    const col3 = getDiv('', 'col-xs-7')
    col3.appendChild(getCTAUI())
    row3.appendChild(col3)

    const col4 = getDiv('', 'col-xs-5')
    col4.appendChild(getAvailabilityUI())
    row3.appendChild(col4)

    detailsContainer.appendChild(row3)

    return detailsContainer
  }

  function getFullUI() {
    const innerContainer = getInnerContainer()

    const imageSection = getImageSection()
    innerContainer.appendChild(imageSection)

    const detailsSection = getDetailsSection()
    innerContainer.appendChild(detailsSection)

    return innerContainer
  }

  function getHighlightUI() {
    const innerContainer = getInnerContainer()

    const nameDiv = getDiv('', 'facility-summary-card__title', uiData.title.text)
    innerContainer.appendChild(nameDiv)

    return innerContainer
  }

  function getMainUI() {
    if (isEmpty(uiData)) {
      return null
    }
    const mainContainer = getMainContainer(null, mainStyle)
    const outerContainer = getOuterContainer()

    let content = null
    if (isHighlight) {
      content = getHighlightUI()
    } else {
      content = getFullUI()
    }

    outerContainer.appendChild(content)
    mainContainer.appendChild(outerContainer)

    return mainContainer
  }

  return getMainUI()
}

export default PopupRAFacility
