import {getLabel} from '../../../uiBuilders/UIUtil'

function Label({
  id = '', classNames = '', content = '', style = ''
}) {
  function getUI() {
    const label = getLabel(id, classNames, content, style)

    return label
  }

  return getUI()
}

export default Label
