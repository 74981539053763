import {getIcon} from '../../../uiBuilders/UIUtil'

function InlineIcon({
  id = '',
  classNames = ''
}) {
  function getUI() {
    const icon = getIcon(id, `a1mv__icon ${classNames}`)
    return icon
  }

  return getUI()
}

export default InlineIcon
