/* eslint-disable max-len */
import {
  isEmpty,
  log,
  parseIfRequired
} from '../../../../utils/GeneralUtils'

import Div from '../../base/Div'
import Intl from '../../../../intl/Intl'
import PopupMainContainer from '../PopupMainContainer'
import Section from '../../base/layout/Section'
import UI from '../../base/UI'

function PopupShape(feature, callback, mainStyle = '', isHover = false) {
  // eslint-disable-next-line no-param-reassign
  // feature = parseIfRequired(feature)
  function getName() {
    let nm = ''
    if (!isEmpty(feature.properties.name)) {
      nm = feature.properties.name
    } else {
      nm = Intl.simpleTranslate(`markerIconType_${feature.properties.shapeStyleId}`, '')
    }

    return nm
  }

  function getNameUI() {
    let classNames = ''
    if (!isHover) {
      classNames = 'a1mv__popup-head'
    }

    const container = Div({classNames, content: getName()})

    return container
  }

  function getDescriptionUI() {
    let content = ''
    if (!isEmpty(feature.properties.description)) {
      content = feature.properties.description
    }
    const container = Div({classNames: '', content})

    return container
  }

  function getTrailTypeUI() {
    const container = Div({classNames: ''})
    if (!isEmpty(feature.properties.trailType)) {
      const trailTypes = JSON.parse(feature.properties.trailType)
      if (!isEmpty(trailTypes)) {
        let trailTypesStr = ''
        const trailTypeHead = Div({classNames: 'a1mv__popup-head', content: Intl.simpleTranslate('trailType')})
        UI.add(container, trailTypeHead)
        trailTypes.forEach((trailType) => {
          // const trailTypeUI = Div({content: Intl.simpleTranslate(`trailType_${trailType}`, trailType)})
          // UI.add(container, trailTypeUI)
          let prepender = ''
          if (!isEmpty(trailTypesStr)) {
            prepender = ', '
          }
          trailTypesStr += prepender + Intl.simpleTranslate(`attr_${trailType}`, trailType)
        })

        const trailTypesUI = Div({content: trailTypesStr})
        UI.add(container, trailTypesUI)
      }
    }

    return container
  }

  function getAvailabilityUI() {
    const container = Div({classNames: ''})
    if (!isEmpty(feature.properties.generalAvailability)) {
      const head = Div({classNames: 'a1mv__popup-head', content: Intl.simpleTranslate('generalAvailability')})
      UI.add(container, head)

      const content = Div({content: Intl.simpleTranslate(`attr_${feature.properties.generalAvailability}`, feature.properties.generalAvailability)})
      UI.add(container, content)
    }

    return container
  }

  function getNoteUI() {
    const container = Div({classNames: ''})
    if (!isEmpty(feature.properties.note)) {
      const head = Div({classNames: 'a1mv__popup-head', content: Intl.simpleTranslate('note')})
      UI.add(container, head)

      const content = Div({content: feature.properties.note})
      UI.add(container, content)
    }

    return container
  }

  function getFullUI() {
    const fullUIcontainer = Div({classNames: 'shape'})

    const nameUI = getNameUI()
    UI.add(fullUIcontainer, nameUI)

    const descriptionUI = getDescriptionUI()
    UI.add(fullUIcontainer, descriptionUI)

    if (!isEmpty(nameUI) || !isEmpty(descriptionUI)) {
      UI.add(fullUIcontainer, Section({}))
    }

    const trailTypeUI = getTrailTypeUI()
    UI.add(fullUIcontainer, trailTypeUI)
    if (!isEmpty(trailTypeUI)) {
      UI.add(fullUIcontainer, Section({}))
    }

    const availabililityUI = getAvailabilityUI()
    UI.add(fullUIcontainer, availabililityUI)
    if (!isEmpty(availabililityUI)) {
      UI.add(fullUIcontainer, Section({}))
    }

    const noteUI = getNoteUI()
    UI.add(fullUIcontainer, noteUI)

    return fullUIcontainer
  }

  function getHoverUI() {
    const hoverContainer = Div({})

    UI.add(hoverContainer, getNameUI())

    return hoverContainer
  }

  function getMainUI() {
    if (isEmpty(feature)) {
      return null
    }
    const mainContainer = PopupMainContainer({mainStyle})

    let content = null
    if (isHover) {
      content = getHoverUI()
    } else {
      content = getFullUI()
    }

    UI.add(mainContainer, content)

    return mainContainer
  }

  return getMainUI()
}

export default PopupShape
