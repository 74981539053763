import {getAnchor} from '../../../uiBuilders/UIUtil'
import {isEmpty} from '../../../utils/GeneralUtils'

function Anchor({
  id = null, classNames = '', content = '', url = '', onClick = null
}) {
  function getUI() {
    const div = getAnchor(id, classNames, content, url)

    if (!isEmpty(onClick)) {
      div.addEventListener('click', (event) => {
        onClick(event)
      })
    }

    return div
  }

  return getUI()
}

export default Anchor
