import Div from '../Div'
import {isEmpty} from '../../../../utils/GeneralUtils'

export const L1 = 'a1mv__header-l1 '
export const L2 = 'a1mv__header-l2 '
export const L3 = 'a1mv__header-l3 '

function Header({
  text = '', type = L1, classNames = '', asSpan = false
}) {
  function getUI() {
    const classes = type + (isEmpty(classNames) ? '' : ` ${classNames}`)
    const mainContainer = Div({asSpan, classNames: classes, content: text})

    return mainContainer
  }

  return getUI()
}

export default Header
