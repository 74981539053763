import GeoMap from '../map/GeoMap'

const A1MapViewer = {
  callbackFunc: null,
  config: null,

  internalInit() {
    // eslint-disable-next-line no-unused-vars
    const geoMap = GeoMap.get()
  },

  init(containerId, mapConfig, mainConfig, clientCallback, mainConfigPath, customizedAmenityConfig = null, satelliteStyle) {
    this.callbackFunc = clientCallback
    this.config = mainConfig
    GeoMap.get().init(containerId, mapConfig, mainConfig, clientCallback, mainConfigPath, customizedAmenityConfig, satelliteStyle)
    // this.callbackFunc({test: 'abcd'})
  },

  setData(data) {
    GeoMap.get().setData(data)
  },

  updateData(data, updateMapConfig = null) {
    GeoMap.get().updateData(data, updateMapConfig)
  },

  highlight(ids) {
    GeoMap.get().highlight(ids)
  },

  fitBoundsToFeatures(featuresIds){
    GeoMap.get().fitBoundsToFeatures(featuresIds)
  },
  updateAmenityTypeConfig(customizedAmenityConfig) {
    GeoMap.get().updateAmenityTypeConfig(customizedAmenityConfig)
  },

  getMapImage() {
    return GeoMap.get().getMapImage()
  },

  getXYForId(id) {
    return GeoMap.get().getXYForId(id)
  },
  execMapPlugin(func) {
    return function () {
      return GeoMap.get().execMapPlugin(func)
    }
  },

  showPopUpByFeature(feature){
     GeoMap.get().createPopupByFeature(feature);
  },

  closeAllPopups(){
    GeoMap.get().closeAllPopups();
 }
}

export default A1MapViewer
