import Div from '../Div'
import {isEmpty} from '../../../../utils/GeneralUtils'
import UI from '../UI'

function FlexLayout({content = []}) {
  function getUI() {
    const containerUI = Div({classNames: 'flex-layout'})

    if (!isEmpty(content)) {
      content.forEach((contentUI) => {
        UI.add(containerUI, contentUI)
      })
    }

    return containerUI
  }

  return getUI()
}

export default FlexLayout
