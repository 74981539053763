import Config from '../../../config/Config'
import {getButton, getDiv, addAttribute} from '../../../uiBuilders/UIUtil'
import {isEmpty, log} from '../../../utils/GeneralUtils'
import UI from './UI'

export function setDisabled(el, disabled = false, bgImageChangeState = false, disabledClass) {
  const domEl = UI.getElement(el)
  if (UI.isElement(domEl)) {
    domEl.disabled = disabled
    if (bgImageChangeState) {
      const disabledTxt = '-disabled'
      if (disabled) {
        const parts = domEl.style.backgroundImage.split('.')
        domEl.style.backgroundImage = parts.join(`${disabledTxt}.`)
        UI.addClass(domEl, disabledClass)
      } else {
        domEl.style.backgroundImage = domEl.style.backgroundImage.replace(disabledTxt, '')
        UI.removeClass(domEl, disabledClass)
      }
    }
  }
}

function Button({
  id = '',
  classNames = '',
  icon = null,
  label = '',
  disabled = false,
  isActionButton = false,
  onClick = null,
  ariaLabel = null,
  bgImage = null,
  bgImagePrepend = 'bu-icon-',
  bgImageExt = 'png',
  bgImageChangeState = false,
  bgImageLinearGradient = ''
}) {
  function getUI() {
    const button = getButton(id, classNames, '', disabled, isActionButton, ariaLabel)

    if (!isEmpty(bgImage)) {
      let bgImageState = ''
      if (bgImageChangeState && disabled) {
        bgImageState = '-disabled'
      }
      const bgImageURL = `${Config.get().getPathsConfig().imageAssetsPath + bgImagePrepend + bgImage + bgImageState}.${bgImageExt}`
      if (isEmpty(bgImageLinearGradient)) {
        addAttribute('style', `background-image: url(${bgImageURL});`, button)
      } else {
        addAttribute('style', `background-image: ${bgImageLinearGradient}, url(${bgImageURL});`, button)
      }
    }

    if (!isEmpty(onClick)) {
      button.addEventListener('click', (event) => {
        onClick(event)
      })
    }

    if (!isEmpty(icon)) {
      button.appendChild(icon)
    }
    if (!isEmpty(label)) {
      const text = getDiv('', '', label, '', true)
      button.appendChild(text)
    }

    return button
  }

  return getUI()
}

export default Button
