import Div from '../Div'
import {isEmpty} from '../../../../utils/GeneralUtils'
import UI from '../UI'

function Inliner({content = [], classNames = ''}) {
  function getUI() {
    // eslint-disable-next-line no-constant-condition
    const containerUI = Div({classNames})

    if (!isEmpty(content)) {
      content.forEach((contentUI) => {
        const inlineDiv = Div({classNames: 'a1mv__inline'})
        UI.add(inlineDiv, contentUI)
        UI.add(containerUI, inlineDiv)
      })
    }

    return containerUI
  }

  return getUI()
}

export default Inliner
