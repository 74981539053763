import Config from '../../../config/Config'
import {log, isEmpty} from '../../../utils/GeneralUtils'
import {
  getMapActionObj, MAP_ACTION_TYPE, MAP_ACTION_ID, MAP_ACTION_STYLE, MAP_ACTION_BUTTON_ID
} from '../../../utils/MapActionsUtils'
import Button from '../base/Button'
import Div from '../base/Div'
import UI from '../base/UI'
import LegendUI from './legend/LegendUI'
// import LegendUI from './legend/LegendUI'
import Intl from '../../../intl/Intl'
import {ACTION_TYPE, getActionObj} from '../../../utils/ActionsUtils'
import CategoriesUI from './categories/CategoriesUI'

function MapActionsUI({
  mainDivId, mapActionCallback, clientCallback, legendData = null, categoryData = 'test'
}) {
  let legendPopupUI = null
  let categoriesPopupUI = null
  let legendPopupUIShowing = false
  let categoriesPopupUIShowing = false
  let overlaysContainer = null
  let mapStyleSwitchButton = null

  const linearGradient = 'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 29%)'

  function removeLegendUI() {
    if (legendPopupUIShowing) {
      UI.removeSelf(legendPopupUI)
      legendPopupUIShowing = false
    }
  }

  function removeCategoriesUI() {
    if (categoriesPopupUIShowing) {
      UI.removeSelf(categoriesPopupUI)
      categoriesPopupUIShowing = false
    }
  }

  function showOrUpdateLegendUI() {
    if (legendPopupUIShowing) {
      UI.removeSelf(legendPopupUI)
    }
    legendPopupUI = LegendUI({
      legendData,
      onClose: () => {
        removeLegendUI()
      }
    })
    if (!isEmpty(legendPopupUI)) {
      UI.add(overlaysContainer, legendPopupUI)
    }

    legendPopupUIShowing = true
  }

  function showOrUpdateCategoriesUI() {
    if (categoriesPopupUIShowing) {
      UI.removeSelf(categoriesPopupUI)
    }
    categoriesPopupUI = CategoriesUI({
      categoryData,
      mapActionCallback,
      onClose: () => {
        removeCategoriesUI()
      }
    })
    if (!isEmpty(categoriesPopupUI)) {
      UI.add(overlaysContainer, categoriesPopupUI)
    }

    categoriesPopupUIShowing = true
  }

  function getPerspectiveUI() {
    let perspectiveButton = null
    const uiOptions = Config.get().getUIOptionsConfig()
    if (uiOptions.perspective.active) {
      perspectiveButton = Button({
        id: 'perspective-button',
        classNames: 'a1mv__map-icon-button',
        bgImage: 'perspective',
        ariaLabel: Intl.simpleTranslate('perspective.button.arialabel', 'Perspective Map'),
        onClick: () => {
          mapActionCallback(getMapActionObj(
            MAP_ACTION_TYPE.ITEM_CLICK,
            null,
            MAP_ACTION_ID.PERSPECTIVE_BUTTON
          ))
        }
      })
    }

    // return wrap(perspectiveButton)
    return perspectiveButton
  }

  function getMapStyleUI() {
    // let mapStyleButton = null
    const uiOptions = Config.get().getUIOptionsConfig()
    const nextMapStyle = Config.get().getNextMapStyle(Config.get().getDefaultMapStyleId())
    if (uiOptions.mapStyle.active) {
      mapStyleSwitchButton = Button({
        id: MAP_ACTION_BUTTON_ID.MAP_STYLE_BUTTON_ID,
        label: Intl.simpleTranslate(nextMapStyle.UIDisplayNameKey, ''),
        classNames: MAP_ACTION_STYLE.MAP_STYLE_BUTTON_STYLE,
        bgImage: nextMapStyle.id,
        bgImageLinearGradient: linearGradient,
        onClick: () => {
          mapActionCallback(getMapActionObj(
            MAP_ACTION_TYPE.ITEM_CLICK,
            null,
            MAP_ACTION_ID.MAP_STYLE_BUTTON
          ))
        }
      })
    }

    return mapStyleSwitchButton
  }

  function getRecenterUI() {
    let recenterButton = null
    const uiOptions = Config.get().getUIOptionsConfig()
    if (uiOptions.recenter.active) {
      recenterButton = Button({
        id: 'recenter-button',
        classNames: 'a1mv__map-icon-button',
        bgImage: 'recenter',
        ariaLabel: Intl.simpleTranslate('recenter.button.arealabel', 'Recenter Map'),
        onClick: () => {
          mapActionCallback(getMapActionObj(
            MAP_ACTION_TYPE.ITEM_CLICK,
            null,
            MAP_ACTION_ID.RECENTER_BUTTON
          ))
        }
      })
    }

    return recenterButton
  }

  function removeUI() {
    removeLegendUI()
    removeCategoriesUI()
  }

  function getPrintUI() {
    let printButton = null
    const uiOptions = Config.get().getUIOptionsConfig()
    if (uiOptions.print.active) {
      printButton = Button({
        id: 'print-button',
        classNames: 'a1mv__map-icon-button a1mv__map-icon-button-secondary',
        bgImage: 'print',
        ariaLabel: Intl.simpleTranslate('print.button.arealabel', 'Print Map'),
        onClick: () => {
          removeUI()
          clientCallback(getActionObj(ACTION_TYPE.PRINT_MAP, {}))
        }
      })
    }

    return printButton
  }

  function getCategoriesUI() {
    let categoriesButton = null
    const uiOptions = Config.get().getUIOptionsConfig()
    if (uiOptions.categories.active) {
      categoriesButton = Button({
        id: 'categories-button',
        classNames: 'a1mv__map-icon-button a1mv__map-icon-button-secondary',
        bgImage: 'categories',
        ariaLabel: Intl.simpleTranslate('categories.button.arealabel', 'Categories'),
        onClick: () => {
          showOrUpdateCategoriesUI()
        }
      })
    }

    return categoriesButton
  }

  function getLegendUI() {
    // Intl.addQualifier('PA')
    let legendButton = null
    const uiOptions = Config.get().getUIOptionsConfig()
    if (uiOptions.legend.active) {
      legendButton = Button({
        id: 'legend-button',
        classNames: 'a1mv__map-text-button',
        label: Intl.simpleTranslate('legendButtonTxt', ''),
        onClick: () => {
          showOrUpdateLegendUI()
        }
      })
    }

    return legendButton
  }

  const api = {
    refreshUI({updLegendData, updCategoryData}) {
      // log('refreshUI')
      // eslint-disable-next-line no-param-reassign
      legendData = updLegendData
      // eslint-disable-next-line no-param-reassign
      categoryData = updCategoryData
      removeUI()
    },

    updateMapStyleSwitchButton(nextMapStyle) {
      if (UI.isElement(mapStyleSwitchButton)) {
        mapStyleSwitchButton.textContent = Intl.simpleTranslate(nextMapStyle.UIDisplayNameKey, '')
        mapStyleSwitchButton.style.backgroundImage = `${linearGradient}, url(${Config.get().getPathsConfig().imageAssetsPath}bu-icon-${nextMapStyle.id}.png)`
      }
    }
  }

  function createMapActionsUI() {
    let mapDiv = mainDivId
    if (typeof mainDivId === 'string') {
      mapDiv = document.getElementById(mainDivId)
    }

    const mainContainer = Div({id: 'gis-map-actions-layer', classNames: 'a1mv__gis-map-actions-layer'})

    const mainButtonsContainer = Div({classNames: 'a1mv__gis-map-main-buttons a1mv__top-left'})
    UI.add(mainContainer, mainButtonsContainer)

    const leftButtonBar = Div({classNames: 'a1mv__gis-map-button-bar'})
    UI.add(mainButtonsContainer, leftButtonBar)

    const perspectiveUI = getPerspectiveUI()
    if (!isEmpty(perspectiveUI)) {
      UI.add(leftButtonBar, perspectiveUI)
    }

    const mapStyleUIContainer = Div({id: 'mapStyleUIContainer', classNames: 'a1mv__map-style-ui-container'})
    UI.add(mainContainer, mapStyleUIContainer)
    const mapStyleUI = getMapStyleUI()
    if (!isEmpty(mapStyleUI)) {
      UI.add(mapStyleUIContainer, mapStyleUI)
    }

    const recenterUI = getRecenterUI()
    if (!isEmpty(recenterUI)) {
      UI.add(leftButtonBar, recenterUI)
    }

    // const categriesButtonContainer = Div({id: 'mapCategoriesUIContainer', classNames: 'a1mv__map-categories-ui-container'})
    // UI.add(mainContainer, categriesButtonContainer)
    // const categoriesUI = getCategoriesUI()
    // if (!isEmpty(categoriesUI)) {
    //   UI.add(categriesButtonContainer, categoriesUI)
    // }

    const secondaryButtonsContainer = Div({classNames: 'a1mv__gis-map-secondary-buttons a1mv__bottom-right'})
    UI.add(mainContainer, secondaryButtonsContainer)

    const legendUI1 = getLegendUI()
    if (!isEmpty(legendUI1)) {
      UI.add(secondaryButtonsContainer, legendUI1)
    }

    const categoriesUI = getCategoriesUI()
    if (!isEmpty(categoriesUI)) {
      UI.add(secondaryButtonsContainer, categoriesUI)
    }

    const printUI = getPrintUI()
    if (!isEmpty(printUI)) {
      UI.add(secondaryButtonsContainer, printUI)
    }

    overlaysContainer = Div({classNames: 'a1mv__gis-map-overlays'})
    UI.add(mainContainer, overlaysContainer)

    mapDiv.appendChild(mainContainer)
  }

  createMapActionsUI()

  return api
}

export default MapActionsUI
