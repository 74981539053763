import {isEmpty, log} from '../../../../utils/GeneralUtils'

import Config from '../../../../config/Config'
import Div from '../../base/Div'
import FlexLayout from '../../base/layout/FlexLayout'
import Header from '../../base/text/Header'
import Icon from './Icon'
import Inliner from '../../base/layout/Inliner'
import Intl from '../../../../intl/Intl'
import UI from '../../base/UI'

function LegendIconUI({category = null}) {
  log('LegendIconUI', category)
  const iconMap = {}
  const statusColorMap = {}

  function getStatusColorUI(iconObj, basePath) {
    const contianer = Div({classNames: 'a1mv__status-box'})

    const statusColorText = iconObj.availableUnmatchedSite ?
               Config.get().getStyleConfig().availableNotMatchSearchLegend || 'Search Criteria Not Matching' 
               : getStatusColorText(iconObj)

    const statusColorTextUI = Div({content: statusColorText, classNames: 'icon-text'})

    if (Config.get().getUIOptionsConfig().legend.useBaseMarkerStatusStyle
    && !isEmpty(iconObj.baseMarkerIconType)
    && iconObj.supportLegendUseBaseMarkerStatusStyle) {
      let styleIdPath = ''
      if (!isEmpty(iconObj.styleId)) {
        styleIdPath = iconObj.styleId
      }

      const iconPath = `${basePath + styleIdPath}/`
      // const iconId = iconObj.baseMarkerIconType + iconObj.dynamicBaseMarkerStatusStyle
      const mk = isEmpty(iconObj.dynamicBaseMarkerStatusStyle) ? '' : iconObj.dynamicBaseMarkerStatusStyle
      const iconId = `${iconPath + iconObj.baseMarkerIconType + mk + (iconObj.availableUnmatchedSite ? '_unmatched':'')}.png`

      const icon = Icon({
        secondImg: null,
        firstImg: iconId,
        classNames: `a1mv__icon-container-${category.id} ${iconObj.availableUnmatchedSite} a1mv__icon-container-${iconObj.styleId} a1mv__legend-icon_${iconObj.statusColorObj.id}${iconObj.iconColorStyle}`
        
      })
      UI.add(contianer, Inliner({content: [icon, statusColorTextUI]}))
    } else {
      const iconColorStyle = `color${iconObj.iconColorStyle}`
      const statusColorBox = Div({classNames: 'a1mv__status-color-box', style: `background: ${iconObj.statusColorObj[iconColorStyle]};`})

      UI.add(contianer, Inliner({content: [statusColorBox, statusColorTextUI]}))
    }

    return contianer
  }

  function getIconUI(iconPath, iconObj) {
    const contianer = Div({classNames: 'a1mv__icon-box'})
    const icon = Icon({
      // secondImg: !isEmpty(iconObj.markerIconPath) ? iconObj.markerIconPath : `${iconPath + iconObj.markerIconType}.png`,
      secondImg: iconObj.markerIconPath,
      firstImg: isEmpty(iconObj.baseMarkerIconType) ? null : `${iconPath + iconObj.baseMarkerIconType}.png`,
      classNames: `a1mv__icon-container-${category.id} a1mv__icon-container-${iconObj.styleId}`,
      firstImgClassNames: isEmpty(iconObj.legendDefaultAttributeType) ? '' : `a1mv__legend-icon_${iconObj.legendDefaultAttributeType}${iconObj.iconColorStyle}` ,
      style:iconObj.customizedLegendStyle||''    
    })
    UI.add(contianer, icon)

    const markerIconTypeText = Intl.simpleTranslate(`markerIconType_${iconObj.markerIconType}`, !isEmpty(iconObj.displayLabel) ? iconObj.displayLabel : iconObj.markerIconType)
    const markerIconTypeTextUI = Div({content: markerIconTypeText, classNames: 'icon-text'})
    UI.add(contianer, Inliner({content: [icon, markerIconTypeTextUI]}))

    return contianer
  }

  function getUI() {
    const mainContainer = Div({id: `legend-${category.id}`, classNames: 'a1mv__legend-pane'})

    const basePath = Config.get().getPathsConfig().iconPath

    const type = category.id
    if (Config.get().getUIOptionsConfig().legend.showStatusColorSection) {
      const statusColorContainer = Div({})
      UI.add(mainContainer, statusColorContainer)

      const statusColorHeader = Config.get().isColorBlindSupport() ? Header({text: `${Intl.simpleTranslate(`legendColorsHeader_cb-${type}`)}`})
        : Header({text: `${Intl.simpleTranslate(`legendColorsHeader-${type}`)}`})

      UI.add(statusColorContainer, statusColorHeader)
      const statusColorUIs = []
      category.data.sort((a,b)=> (a.availableUnmatchedSite ? 1:0)-(b.availableUnmatchedSite ? 1:0));
      category.data.forEach((iconObj) => {
      if (!isEmpty(iconObj.statusColorObj)) {
          if (isEmpty(statusColorMap[iconObj.statusColorObj.id + iconObj.availableUnmatchedSite])) {
            // const statusColorBox = Div({classNames: 'a1mv__status-color-box', style: `background: ${iconObj.statusColorObj.color};`})
            const statusColorUI = getStatusColorUI(iconObj, basePath)
            statusColorUIs.push(statusColorUI)
            // UI.add(statusColorContainer, statusColorBox)
            statusColorMap[iconObj.statusColorObj.id + iconObj.availableUnmatchedSite] = iconObj.statusColorObj
          }
        }
      });
      UI.add(statusColorContainer, FlexLayout({content: statusColorUIs}))
    }

    const iconsContainer = Div({})
    UI.add(mainContainer, iconsContainer)
    const iconsHeader = Header({text: `${Intl.simpleTranslate(`legendIconsHeader-${type}`)}`})
    UI.add(iconsContainer, iconsHeader)
    const iconUIs = []
    category.data.sort((c1, c2) => {
      const cText1 = Intl.simpleTranslate(`markerIconType_${c1.markerIconType}`, !isEmpty(c1.displayLabel) ? c1.displayLabel : c1.markerIconType)
      const cText2 = Intl.simpleTranslate(`markerIconType_${c2.markerIconType}`, !isEmpty(c2.displayLabel) ? c2.displayLabel : c2.markerIconType)
      return cText1.localeCompare(cText2)
    }).forEach((iconObj) => {
      let styleIdPath = ''
      if (!isEmpty(iconObj.styleId)) {
        styleIdPath = iconObj.styleId
      }

      const iconPath = `${basePath + styleIdPath}/`
      if (isEmpty(iconMap[iconObj.markerIconType])) {
        // const icon = Icon({
        //   firstImg: `${iconPath + iconObj.markerIconType}.png`,
        //   secondImg: isEmpty(iconObj.baseMarkerIconType) ? null : `${iconPath + iconObj.baseMarkerIconType}.png`
        // })
        const iconUI = getIconUI(iconPath, iconObj)
        iconUIs.push(iconUI)
        // UI.add(iconsContainer, icon)

        iconMap[iconObj.markerIconType] = iconObj
      }
    })
    UI.add(iconsContainer, FlexLayout({content: iconUIs}))

    return mainContainer
  }

  function getStatusColorText(iconObj) {
    if (iconObj.categoryId == 'slip') {
      return Intl.simpleTranslate(`statusColor_slip_${iconObj.statusColorObj.id}`, iconObj.statusColorObj.name)
    } else {
      return Intl.simpleTranslate(`statusColor_${iconObj.statusColorObj.id}`, iconObj.statusColorObj.name)
    }
  }

  if (isEmpty(category) || isEmpty(category.data)) {
    return null
  }

  return getUI()
}

export default LegendIconUI
