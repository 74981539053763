import {isEmpty, log} from '../utils/GeneralUtils'

export const CLASS_POPUP_PRE = 'gismap_popup_'

export function addAttribute(key, value = null, uiObj) {
  if (!isEmpty(value) && !isEmpty(uiObj)) {
    uiObj.setAttribute(key, value)
  }
}

export function getButton(id = null, classNames = '', label = '', disabled = false, isActionButton = true, ariaLabel = null) {
  let allClassNames = classNames
  if (isActionButton) {
    allClassNames = `${CLASS_POPUP_PRE}action-button ${classNames}`
  }

  const buttonUI = document.createElement('button')
  buttonUI.innerHTML = label
  addAttribute('id', id, buttonUI)
  addAttribute('class', `a1mv__button  ${allClassNames}`, buttonUI)
  addAttribute('type', 'button', buttonUI)
  if (disabled) {
    // log('disabled', disabled)
    buttonUI.disabled = true
  }
  addAttribute('aria-label', ariaLabel, buttonUI)

  return buttonUI
}

export function getInput(type = 'text', id = null, classNames = '', label = '', disabled = false, checked = null) {
  const allClassNames = classNames

  const inputUI = document.createElement('input')
  addAttribute('value', label, inputUI)
  addAttribute('type', type, inputUI)
  addAttribute('id', id, inputUI)
  addAttribute('class', allClassNames, inputUI)
  addAttribute('checked', checked, inputUI)
  if (disabled) {
    // log('disabled', disabled)
    inputUI.disabled = true
  }

  return inputUI
}

export function getAnchor(id = null, classNames = '', content = '', url = '') {
  const anchorUI = document.createElement('a')
  anchorUI.innerHTML = content
  addAttribute('id', id, anchorUI)
  addAttribute('class', classNames, anchorUI)
  addAttribute('href', url, anchorUI)

  return anchorUI
}

export function getImage(id = null, classNames = '', src = null, alt = '', title = '', inlineStyle = '') {
  const imgUI = document.createElement('img')
  addAttribute('id', id, imgUI)
  addAttribute('class', classNames, imgUI)
  addAttribute('src', src, imgUI)
  addAttribute('alt', alt, imgUI)
  addAttribute('title', title, imgUI)
  addAttribute('style', inlineStyle, imgUI)

  return imgUI
}

export function getDiv(id, classNames = '', content = '', style = '', asSpan = false) {
  const type = asSpan ? 'span' : 'div'
  const divUI = document.createElement(type)
  divUI.innerHTML = content
  addAttribute('id', id, divUI)
  addAttribute('class', classNames, divUI)
  addAttribute('style', style, divUI)

  return divUI
}

export function getLabel(id, classNames = '', content = '', style = '') {
  const type = 'label'
  const labelUI = document.createElement(type)
  labelUI.innerHTML = content
  addAttribute('id', id, labelUI)
  addAttribute('class', classNames, labelUI)
  addAttribute('style', style, labelUI)

  return labelUI
}

export function getHR(id, classNames = '') {
  const divUI = document.createElement('hr')
  addAttribute('id', id, divUI)
  addAttribute('class', classNames, divUI)

  return divUI
}

export function getIcon(id, classNames = '') {
  const iUI = document.createElement('i')
  addAttribute('id', id, iUI)
  addAttribute('class', classNames, iUI)
  return iUI
}

export function getItalicUI(classNames = '', ariaHidden = null) {
  const iUI = document.createElement('i')
  addAttribute('class', classNames, iUI)
  addAttribute('aria-hidden', ariaHidden, iUI)

  return iUI
}

/**
 * @deprecated use PopupMainContainer component
 */
export function getMainContainer(id = null, mainStyle = '') {
  const separator = isEmpty(mainStyle) ? '' : '_'
  return getDiv(id, `${CLASS_POPUP_PRE}${mainStyle}${separator}main-content`)
}
