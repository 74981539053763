/* eslint-disable max-len */
import {
  getValue,
  hackFixJSON,
  isEmpty,
  isUndefined
} from '../../../../utils/GeneralUtils'

import Div from '../../base/Div'
import Intl from '../../../../intl/Intl'
import PopupMainContainer from '../PopupMainContainer'
import PopupSections from './PopupSections'
import UI from '../../base/UI'

function PopupCUIHunter(feature, callback, mainStyle = '', isHighlight = false, isHover = false) {
  // TODO - not sure why the uiData gets stringified when passed in - fix.
  hackFixJSON(feature.properties)
  const {uiData} = feature.properties
  function getFullUI() {
    const fullUIcontainer = PopupSections(uiData, feature, callback)
    return fullUIcontainer
  }
  function getHighlightUI() {
    const highlightContainer = PopupSections(uiData, feature, callback)
    return highlightContainer
  }
  function getHoverUI() {
    let hoverUI = null
    const head = getValue(() => uiData.head, null)
    if (!isUndefined(feature.properties.baseMarkerIconType) && feature.properties.baseMarkerIconType.indexOf('amenity') == 0) {
      let title = getValue(() => head.title, null)
      if (isEmpty(title)) {
        title = Intl.simpleTranslate(`markerIconType_${feature.properties.markerIconType}`, '')
      }
      if (!isEmpty(title)) {
        hoverUI = Div({content: title})
      }
    } else {
      hoverUI = Div({content: `${head.siteName}, ${head.siteType}`})
    }
    return hoverUI
  }
  function getMainUI() {
    const mainContainer = PopupMainContainer({mainStyle})
    let content = null
    if (isHover || (!isUndefined(feature.properties.baseMarkerIconType) && feature.properties.baseMarkerIconType.indexOf('amenity') == 0) || isUndefined(uiData.itemAvailabilityStatus)) {
      content = getHoverUI()
      UI.add(mainContainer, content)
    } else if (!isEmpty(uiData)) {
      if (isHighlight) {
        content = getHighlightUI()
      } else {
        content = getFullUI()
      }
      UI.add(mainContainer, content)
      const bottomRowUI = Div({classNames: 'bottom-row'})
      if (!isEmpty(uiData.price)) {
        const price = Div({classNames: 'price', content: uiData.price})
        UI.add(bottomRowUI, price)
      }
      UI.add(mainContainer, bottomRowUI)
      if (!isEmpty(uiData.footnote)) {
        const footnote = Div({classNames: 'small', content: uiData.footnote, asSpan: true})
        UI.add(mainContainer, footnote)
      }
    }
    return mainContainer
  }
  return getMainUI()
}
export default PopupCUIHunter
