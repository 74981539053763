import {hackFixJSON} from './GeneralUtils'

export const ACTION_TYPE = {
  FEATURE_HOVER_ENTER: 'featureHoverEnter',
  FEATURE_HOVER_LEAVE: 'featureHoverLeave',
  FEATURE_CLICK: 'featureClick',
  ACTION_ITEM_CLICK: 'actionItemClick',
  DISPLAYED_FEATURES_CHANGE: 'displayedFeaturesChange',
  PRINT_MAP: 'printMap'
}

export function getActionObj(type, properties, id = null, hackJSONFixRequired = true) {
  if (hackJSONFixRequired) {
    hackFixJSON(properties)
  }

  return {
    actionType: type,
    targetProperties: properties,
    id
  }
}
